.main{
    width: 370px;
    height: 630px;
    background-color: white;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    border-radius: 4px;
    float: left;
    margin-right: 20px;
    overflow: scroll;
}

.tableEntry{
    width: 80%;
    height: 65px;
    float: left;

}

.tableEntry2{
    width: 15%;
    height: 65px;
    float: left;

}

.tableTitleDiv{
    width: 80%;
    height: 60px;
    float: left;
}

.tableTitleDiv2{
    width: 15%;
    height: 60px;
    float: left;
}

.tableTitle{
    font-size: 15px;
    margin-left: 20px;
    color: rgb(169, 168, 168);
    font-family: 'Source Code Pro';
    font-weight: 700;
}

.tableTitle2:hover{
    cursor: pointer;
}

.tableTitle2{
    font-size: 15px;
    color: rgb(169, 168, 168);
    font-family: 'Source Code Pro';
    font-weight: 700;
    text-align: center;
}

.tableEntry img{
    width: 50px;
    height: 50px;
    border-radius: 4px;
    float: left;
    margin-left: 20px;
}

.title{
    display: inline-block;
    float: left;
    font-size: 14px;
    margin-top: 7px;
    margin-bottom: 2px;
    margin-left: 10px;
    width: 150px;
    font-family: 'Source Code Pro';
}

.title2{
    font-size: 15px;
    margin-top: 13px;
    margin-bottom: 2px;
    color: rgb(40, 40, 40);
    font-family: 'Source Code Pro';
    font-weight: 400;
    text-align: center;
}

.sku{
    display: inline-block;
    font-size: 13px;
    margin-top: 2px;
    margin-left: 10px;
    width: 150px;
    color: rgb(111, 111, 111);
}