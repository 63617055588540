.admin-div_produtos-products{
    height: 2650px;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-top: 0px;
}

.admin_catalog_search{
    width: 100%;
    height: 50px;
    margin-top: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-box-sizing:border-box;
    -moz-box-sizing: border-box;
    box-shadow:inset 7px 5px 17px 3px rgb(242, 242, 242);
    color: #B5B4B7;
    font-size: 14px;
    padding-left: 25px;
}

.admin_catalog_search:focus{
    outline: none;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #B5B4B7;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #B5B4B7;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #B5B4B7;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #B5B4B7;
  }

  .admin_catalog_div_button{
    width: 50%;
    height: 50px;
    margin-bottom: 25px;
    float: right;
  }

.admin_catalog_responsive{
  max-width: 1170px;
  margin: auto;
}

.admin_pages_title{
  width: 100%;
  height: 90px;
  background-color: white;
  margin-bottom: 25px;
}

.admin_pages_title_divResponsive{
  max-width: 1170px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}

.admin_pages_title_divResponsive h1{
  width: 50%;
  float: left;
  margin-top: 30px;
  font-family: 'Source Code Pro';
  font-weight: 500;
  font-size: 41px;
}

.admin_pages_title_divResponsive2{
  max-width: 1140px;
  margin: auto;
  height: 100%;
  overflow: hidden;
}

.admin_pages_title_divResponsive2 h1{
  width: 50%;
  float: left;
  margin-top: 30px;
  font-family: 'Source Code Pro';
  font-weight: 500;
  font-size: 41px;
}