.adminOrdersOrder-div{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ededed;
}

.adminOrdersOrder-div_shopify{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ededed;
}

.adminOrdersOrder-div:hover{
    cursor: pointer;
    background-color: rgb(250, 250, 250);
}

.adminOrdersOrder-div-div3{
    width: 33%;
    height: 100%;
    float: left;
}

.adminOrdersOrder-div-id{
    float: left;
    margin-top: 20px;
    width: 15%;
    font-size: 12px;
    font-weight: 700;
}
.adminOrdersOrder-div-date{
    float: left;
    margin-top: 20px;
    width: 35%;
    font-size: 12px;
    font-weight: 700;
    color: #8b8a8a;
}

.adminOrdersOrder-div-fulfilled{
    float: left;
    margin-top: 20px;
    width: 30%;
    color: green;
    font-size: 12px;
    font-weight: 800;
}
.adminOrdersOrder-div-total{
    float: left;
    margin-top: 20px;
    width: 20%;
    text-align: right;
    font-size: 12px;
    font-weight: 600;
}

.adminOrdersOrder-div-div3-data-f{
    font-size: 11px;
    font-weight: 400;
    margin-top: 0;
    color: black;
    background-color: rgb(173, 223, 173);
    width: 60px;
    height: 17px;
    text-align: center;
    border-radius: 5px;
    padding-top: 2px;
}

.adminOrdersOrder-onequarter1{
    width: 18%;
    float: left;
}

.adminOrdersOrder-onequarter2{
    width: 25%;
    float: left;
}

.adminOrdersOrder-onequarter3{
    width: 14%;
    float: left;
}

.adminOrdersOrder-onequarter4{
    width: 8%;
    float: left;
}

.adminOrdersOrder-onequarter5{
    width: 10%;
    float: left;
}

.adminOrdersOrder-onequarter6{
    width: 12.5%;
    float: left;
}

.adminOrdersOrder-onequarter7{
    width: 12.5%;
    float: left;
}

.adminOrdersOrder-onequarter1-p2{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color:black;
    margin-top: 12px;
}

.adminOrdersOrder-onequarter1-p5{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color:black;
    margin-top: 12px;
    background-color: #E4E5E7;
    border-radius: 10px;
}

.ADMIN_OrdersOrder-div-notview{
    border: 1px solid rgb(255, 170, 156);
        width: 1198px;
        height: 75px;
        margin-bottom: 10px;
    
}