.shopcartmain-div{
    width: 1100px;
    min-height: 550px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 100px;
}

.shopcartmain-div-line{
    width: 87%;
    height: 1px;
    background-color: rgb(206, 205, 205);
    margin-bottom: 10px;
}

.shopcartmain-div-button{
    width: 300px;
    margin: auto;
    margin-top: 40px;
}

.shopcartmain-div-h1{
    text-align: center;
}

.shopcartmain-div-totalprice{
    font-size: 22px;
    font-family: 'Source Code Pro';
    font-weight: 300;
}

.shopcart-textarea{
    width: 100%;
    border: 1px solid #c2c2c2;
    height: 100px;
    color: black;
}

.shopcart-form-p{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-top: 17px;
    margin-left: 15px;
}

.shopcart-checkbox{
    width: 10px;
    height: 10px;
    border: 1px solid #c2c2c2;
    float: left;
    margin-top: 19px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

.shopcart-form-p-adress{
    font-size: 9px;
    color: #8e8d8d;
}

.terms_conditions{
    font-size: 12px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.div_terms_conditions{
    width: 90%;
    margin-left: 30px;
}