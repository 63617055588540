.userOrderProducts-div{
    width: 530px;
    margin: auto;
    margin-top: 30px;
}

.userOrderProducts-div-line{
    width: 100%;
    height: 3px;
    background-color: rgb(206, 205, 205);
    margin-bottom: 10px;
}

.UserOrderProducts-div-header{
    width: 800px;
    height: 100px;
    margin: auto;
}

.userOrdersOrder-divUnique{
    overflow: auto;
    height: 680px;
}

.order-specific-info{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}