.USER_ORDER_PRODUCT_quantitysList{
    height: 250px;
    width: 87%;
    float: left;
    margin-bottom: 20px;
    margin-top: 20px;
}
.USER_ORDER_quantitySize{
    height: 200px;
    width: 100%;
    float: left;
    background-color: #F7F7F7;
    overflow-x: scroll;
    white-space: nowrap;
}

.USER_ORDER_quantitySize_moreInfo{
    height: 50px;
    width: 100%;
    float: left;
    background-color: #F7F7F7;
}

::-webkit-scrollbar  {
    -webkit-appearance: none;
    height: 9px;
  }
  
  ::-webkit-scrollbar-thumb:horizontal  {
    border-radius: 0px;
    background-color: #b1b1b1;
    height: 2px;
    width: 10px;
  }