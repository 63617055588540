.admin-header{
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 230px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
     /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    background-color: white;
    padding-top: 0;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    float: left;
}

.admin-header2{
    height: 100%; /* Full-height: remove this if you want "auto" height */
    width: 230px; /* Set the width of the sidebar */
    position: fixed; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 0; /* Stay at the top */
    left: 0;
    overflow-x: hidden; /* Disable horizontal scroll */
    background-color: white;
    padding-top: 0;
    float: left;
}

.admin-header-link{
    width: 100%;
    height: 40px;
    text-decoration: none;
    padding-top: 0;
    color: #B5B4B7;
}

.ordersNumber{  
    width: 24px;
    height: 24px;
    border-radius: 20px;
    margin-right: 25px;
    background-color: #052F56;
    float: right;
}

.ordersNumber p{
    color: white;
    margin-top: 2px;
    margin-left: 7px;
}

.admin-header-link > *{
    color: #B5B4B7;
}

.admin-header-link:hover > *{
    color: black;
}

.admin-link{
    font-size: 15px;
    text-decoration: none;
    width: 35%;
    height: 100%;
    display: inline-block;
    margin-left: 20px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 0;
}

.admin-link2{
    font-size: 15px;
    text-decoration: none;
    width: 35%;
    height: 100%;
    display: inline-block;
    margin-left: 100px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 0;
}

.admin-header-link-image{
    width: 90%;
    height: 100px;
    margin-bottom: 20px;
}

.admin-header-link-image img{
    width: 100px;
    height: 34px;
    margin-left: 59px;
    margin-top: 20px;
}

.admin-link-icon{
    font-size: 17px;
    text-decoration: none;
    margin-top: 2px;
    display: block;
    float: left;
    margin-left: 37px;
}

.admin-link-icon2{
    font-size: 19px;
    text-decoration: none;
    margin-top: 1px;
    display: block;
    float: left;
    margin-left: 37px;
}

.admin_selected > *{
    color: black;
}

.admin-logout{
    cursor: pointer;
}

.admin-header-link-div-bottom{
    width: 100%;
    height: 180px;
    position: absolute;
    bottom: 0;
    border-top: 1px solid #EBEFF2;
}

.admin-header-link2{
    width: 100%;
    height: 30px;
    text-decoration: none;
    padding-top: 0px;
    margin-bottom: 0px;
    color: #B5B4B7;
}

.admin-header-link2 > *{
    color: #B5B4B7;
}

.admin-header-link2:hover > *{
    color: black;
}

.admin_name{
    font-size: 15px;
    margin-left: 37px;
    margin-bottom: 0;
}

.admin_email{
    font-size: 11px;
    margin-left: 37px;
    margin-top: 4px;
    margin-bottom: 18px;
    color: #797979;
}

.admin_pertodesign{
    font-size: 11px;
    color: #B5B4B7;
    margin-left: 37px;
}

.admin_pertodesign span{
    font-weight: 700;
}

.dqdq:hover{
    height: 200px;
}

.dqdq{
    height: 40px;
    overflow: hidden;
    transition: height 0.8s;
}
