tr:nth-child(even) {
    background-color: white;
}

th, td {
    padding: 15px;
    text-align: left;
}

table{
    border-collapse: collapse;
}

.inputUsers_excel{
    display: block; 
    width: 170px;
    height:30px;
    padding-top: 10px;
    background-color:#052F56;
    color:white;
    font-family: 'Source Code Pro';
    font-size: 13px;
    font-weight: 600;
    text-align:center;
    border-radius: 5px;
    margin: auto;
    margin-top: 30px;
}

.inputUsers_excel:hover{
    cursor: pointer;
    background-color: #07457e;
}