.userOrders-div2{
    width: 700px;
    min-height: 630px;
    margin: auto;
    margin-top: 40px;
    overflow: auto;
}

.userOrders-title_page{
    font-size: 19px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.adminOrdersOrder-div-div3-data2{
    font-size: 11px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: black;
    width: 110px;
    text-align: center;
}

.adminOrdersOrder-div-div3-title2{
    font-size: 11px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: black;
    margin-left: 20px;
    width: 110px;
    text-align: center;
}

.adminOrdersOrder-div-div3-title-f2{
    font-size: 11px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: black;
    width: 90px;
    text-align: center;
}