.main{
    width: 100%;
    height: 288px;
    background-color: white;
    border-radius: 4px;
    margin-right: 20px;
    display: inline-block;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    margin-bottom: 24px;
}

.line{
    width: 100%;
    height: 1px;
    background-color: rgb(184, 184, 184);
    margin-bottom: 30px;
}

.title{
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 20px;
}

.name{
    font-size: 13px;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 30px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: rgb(135, 135, 135);
}

.value{
    float: right;
    font-size: 18px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: #FFB946;
}

.value1{
    float: right;
    font-size: 18px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: #109CF1;
}

.value2{
    float: right;
    font-size: 18px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: #A57AFA;
}

.value3{
    float: right;
    font-size: 18px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: #2ED47A;
}

.value4{
    float: right;
    font-size: 18px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    color: #FF7364;
}

.point{
    width: 9px;
    height: 9px;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #FFB946;
    float: left;
    border-radius: 10px;
}

.point1{
    width: 9px;
    height: 9px;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #109CF1;
    float: left;
    border-radius: 10px;
}

.point2{
    width: 9px;
    height: 9px;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #A57AFA;
    float: left;
    border-radius: 10px;
}

.point3{
    width: 9px;
    height: 9px;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #2ED47A;
    float: left;
    border-radius: 10px;
}

.point4{
    width: 9px;
    height: 9px;
    margin-top: 5px;
    margin-right: 5px;
    background-color: #FF7364;
    float: left;
    border-radius: 10px;
}