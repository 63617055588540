.banner{
    width: 100%;
    height: 30px;
    background-color: #F2F2F2;
    padding-top: 0px;
}

.banner p{
    display: inline-block;
    width: 100%;
    text-align: center;
    color: #0A8142;
    font-size: 12px;
    font-weight: 500;
    margin-top: 7px;
}