.admin-addUser-div{
    width: 640px;
    height: 630px;
    margin: auto;
    margin-top: 50px;
}

.admin-addUser-div2{
    width: 300px;
    height: 90%;
    float: left;
    margin-right: 37px;
}

.admin-addUser-div22{
    width: 300px;
    height: 90%;
    float: left;
}


.admin-addUser-div-p{
    font-size: 12px;
}

.admin-addUser-div-input{
    width: 98.5%;
    height: 25px;
    border: 1px solid #e6dfda;
    font-size: 10px;
    outline-color: #c9c3be;
}

.subtitle{
    font-size: 17px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-top: 40px;
}

.container_title{
    width: 1150px;
    margin-left: 20px;
}