.qtySizeData-div{
    width: 55px;
    height: 165px;
    display: inline-block;
    margin-left: 6px;
    overflow: hidden;
    margin-top: 25px;
}

.qtySizeData-div-size{
    width: 95%;
    height: 30px;
    border: 1px solid #AFAFAF;
    overflow: hidden;
}

.qtySizeData-div-size p{
    margin: 0;
    margin-top: 7px;
    font-size: 13px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.qtySizeData-div-stock{
    width: 95%;
    height: 20px;
    border-bottom: 1px solid #AFAFAF;
    border-right: 1px solid #AFAFAF;
    border-left: 1px solid #AFAFAF;
    overflow: hidden;
}

.qtySizeData-div-stock-green{
    margin: 0;
    margin-top: 2px;
    color: green;
    font-size: 13px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.qtySizeData-div-qty{
    width: 95%;
    height: 30px;
    border: 1px solid #AFAFAF;
    margin-top: 15px;
}

.qtySizeData-div-qty input{
    width: 90%;
    border: 0;
    outline: none;
    font-size: 13px;
    margin-top: 6px;
    text-align: center;
    background-color: #F7F7F7;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.qtySizeData-div-price{
    height: 20px;
    margin-top: 10px;
    margin-bottom: 0;
    font-size: 12px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 300;
}

.qtySizeData-div-pricepvp{
    height: 20px;
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 11px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 300;
}

.redColor{
    margin: 0;
    margin-top: 2px;
    color: red;
    font-size: 13px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

input[type=number]::-webkit-inner-spin-button {
    opacity: 1;
}