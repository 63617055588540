.mainDiv{
    width: 97%;
    height: 262px;
    margin: auto;
    background-color: white;
    /*box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);*/
    margin-bottom: 2px;
}

.photoDiv{
    width: 18%;
    height: 100%;
    float: left;
}

.photoDiv p{
    margin-left: 15px;
    font-family: 'Source Code Pro';
    font-size: 13px;
}

.photoDiv img{
    width: 175px;
    height: 175px;
    margin-left: 15px;
}

.infoDiv{
    width: 82%;
    height: 100%;
    float: left;
}

.infoDivUp{
    width: 89%;
    height: 135px;
    background-color: #fbfbfc;
    margin-top: 72px;
    overflow-x: scroll;
    white-space: nowrap;
}

::-webkit-scrollbar  {
    -webkit-appearance: none;
    height: 9px;
  }
  
::-webkit-scrollbar-thumb:horizontal  {
    border-radius: 3px;
    background-color: rgb(215, 215, 215);
  }

/* div of stock, price.. */
.sizeInfoDiv{
    width: 90px;
    height: 100%;
    display: inline-block;
}

.size{
    width: 68px;
    height: 27px;
    border: 1px solid #D4D2D6;
    border-radius: 3px;
    margin-left: 10px;
    margin-top: 10px;
}

.size p{
    text-align: center;
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-top: 5px;
}

.stock{
    width: 68px;
    height: 27px;
    border: 1px solid #D4D2D6;
    border-radius: 3px;
    margin-left: 10px;
    margin-top: 5px;
}

.stock p{
    text-align: center;
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-top: 5px;
}

.price{
    text-align: center;
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 0px;
}

/* Div down */

.infoDivDown{
    width: 97%;
    height: 50px;
    margin-right: 20px;
}

.infoDivDown p{
    float: right;
    margin-left: 80px;
    margin-top: 17px;
    font-family: 'Source Code Pro';
    font-size: 13px;
}

/* Legends */

.legendDiv{
    width: 8%;
    height: 135px;
    margin-top: 72px;
    float: left;
}

.legendDiv p{
    font-size: 13px;
    font-family: 'Source Code Pro';
    margin-top: 17px;
}

/* Component2 */

.component2{
    width: 97%;
    height: 200px;
    margin: auto;
    /*box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);*/
    margin-top: 15px;
    margin-bottom: 20px;
}

.bucket1{
    width: 40%;
    height: 100%;
    background-color: white;
    float: left;
    margin-right: 17px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.bucket1 p{
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 500;
}

.bucket2{
    width: 24%;
    height: 100%;
    background-color: white;
    float: left;
    margin-right: 17px;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.bucket2 p{
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 500;
}

.bucket3{
    width: 33%;
    height: 100%;
    background-color: white;
    float: left;
    border-radius: 3px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.bucket3 p{
    font-family: 'Source Code Pro';
    font-size: 13px;
    margin-left: 20px;
    margin-right: 20px;
    font-weight: 500;
    margin-top: 40px;
    margin-bottom: 35px;
}

.bucket3 div{
    width: 90%;
    height: 2px;
    background-color: black;
    margin-left: 18px;
}

.bucket3 p span{
    float: right;
    font-size: 17px;
    font-weight: 100;
}