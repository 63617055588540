.qtyfullsize{
    margin-bottom: 20px;
}

.qtyfullsize-infoprice{
    float: right;
    width: 248px;
    height: 40px;
    border: 1px solid #c2c2c2;
}

.qtyfullsize-infoprice-noborder{
    float: right;
    width: 250px;
    height: 40px;
}

.qtyfullsize-infoprice p{
    font-size: 12px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 15px;
}

.qtyfullsize-infoprice-div{
    width: 100%;
    height: 43px;
    float: left;
    margin-bottom: 7px;
}

.QUANTITY_quantitySize{
    width: 1040px;
    height: 210px;
    float: left;
    background-color: #F7F7F7;
    margin-bottom: 20px;
    margin-top: 20px;
    overflow-x: scroll;
    white-space: nowrap;
}

::-webkit-scrollbar  {
    -webkit-appearance: none;
    height: 9px;
  }
  
  ::-webkit-scrollbar-thumb:horizontal  {
    border-radius: 0px;
    background-color: #b1b1b1;
    height: 2px;
    width: 10px;
  }