.div-login_footer2{
    width: 100%;
    height: 35vh;
    background-color: #F2F2F2;
    position: absolute;
    margin-top: auto;
}

.div_footer_information2{
    width: 100%;
    height: 80%;
    float: left;
    overflow: hidden;
}

.div-footer-email2{
    height: 100%;
    width: 43%;
    float: left;
    margin-top: 40px;
}

.div-footer-email-contact2{
    font-size: 12px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 70px;
    margin-top: 6px;
    color: black;
}
/*
.div-footer-email-margin{
    width: 330px;
    height: 150px;
    margin: auto;
}

.div-footer-email-divInput{
    float: left;
    width: 180px;
    height: 40px;
}

.div-footer-email-divButton{
    float: left;
    width: 120px;
    height: 40px;
}

.div-footer-email-input{
    width: 179px;
    max-width: 179px;
    height: 32px;
    margin: 0;
    outline: none;
    border: none;
}

.div-footer-email-button{
    width: 120px;
    height: 34px;
    border-radius: 0px;
    border: 1px solid #dcdcdc;
    cursor: pointer;
    background-color: #dcdcdc;
}

.div-footer-about{
    height: 100%;
    width: 19%;
    float: left;
    margin-top: 35px;
}

.div-footer-info{
    height: 100%;
    width: 19%;
    float: left;
    margin-top: 35px;
}

.div-footer-contact{
    height: 100%;
    width: 19%;
    float: left;
    margin-top: 35px;
}

.line_footer{
    width: 100%;
    background-color: rgb(229, 226, 226);
    height: 3px;
    overflow: hidden;

}

.div-footer-about-title{
    font-size: 12px;
    font-weight: 700;
    margin-bottom: 20px;
}

.div-footer-about-links{
    font-size: 11px;
    font-weight: 500;
    cursor: pointer;
}

.p_footer_developer{
    font-size: 10px;
    margin-left: 20px;
    margin-top: 20px;
}


}*/