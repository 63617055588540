.main{
    width: 100%;
    background-color: white;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    border-radius: 4px;
    float: left;
}

.headerDataDiv{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ededed;
}

.headerDataDiv:hover{
    background-color: #f7f7f7;
}

.headerData{
    width: 20%;
    height: 40px;
    float: left;
}

.headerData p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
}

.headerData2{
    width: 13%;
    height: 40px;
    float: left;
}

.headerData2 p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
}

.headerData3{
    width: 27%;
    height: 40px;
    float: left;
}

.headerData3 p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
}

.tableData{
    width: 20%;
    height: 40px;
    float: left;
}

.tableData p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
    font-size: 12px;
}

.tableData2{
    width: 13%;
    height: 40px;
    float: left;
}

.tableData2 p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
    font-size: 12px;
}

.tableData3{
    width: 27%;
    height: 40px;
    float: left;
}

.tableData3 p{
    text-align: center;
    font-family: 'Source Code Pro';
    margin-top: 10px;
    font-size: 12px;
}