.main{
    margin-left: 230px;
}

.main h1{
    margin-left: 40px;
}

.main h3{
    margin-left: 40px;
    margin-top: 58px;
}

.main input{
    margin-left: 40px;
    width: 350px;
    height: 30px;
    font-size: 15px;
    margin-top: 6px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.main img{
    margin-left: 40px;
    width: 700px;
}

.main button{
    margin-left: 40px;
    margin-bottom: 20px;
    margin-top: 30px;
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #2ED47A;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.main button:hover{
    cursor: pointer;
    background-color: #18fc7f;
}