.dashboard{
    margin-left: 230px;
    min-height: 100vh;
    background-color: #F4F3F8;
}

.dashmoard_mainDiv{
    max-width: 1170px;
    margin: auto;
    height: 600px;
    margin-top: 25px;
}

.dashboard_overview_mainDiv_titleDiv{
    width: 100%;
    height: 120px;
    background-color: white;
}

.dashboard_overview_titleDiv{
    max-width: 1170px;
    margin: auto;
    height: 120px;
    overflow: hidden;
}

.dashboard_overview_titleDiv h1{
    margin-top: 50px;
    margin-bottom: 0px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 41px;
    width: 70%;
    float: left;
}

.dashboard_overview_left{
    width: 70%;
    height: 100%;
    float: left;
}

.dashboard_overview_left_smallComponents{
    width: 100%;
    height: 22%;
}

.dashboard_overview_left_graphic{
    width: 100%;
    height: 85%;
    margin-top: 10px;
}

.dashboard_overview_left_graphic_div{
    background-color: white;
    width: 97%;
    height: 90%;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    border-radius: 4px;
}

.dashboard_overview_right{
    width: 30%;
    height: 100%;
    float: left;    
}

.dashboard_graph1{
    width: 800px;
    height: 500px;
}

.dashboard_optionsDiv{
    width: 200px;
    height: 35px;
    background-color: #052F56;
    float: left;
    margin-top: 65px;
    border-radius: 4px;
    float: right;
}

.dashboard_optionsDiv:hover{
    cursor: pointer;
}

.dashboard_optionsDiv p{
    color: white;
    margin-top: 7px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.dashboard_optionsDiv_icon{
    margin-top: 8px;
    display: inline;
    float: left;
    margin-left: 50px;
    color: white;
    margin-right: 7px;
}