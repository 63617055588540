.user-header{
    height: 110px;
    position: sticky;
    top: 0;
    border-bottom: 2px solid #F2F2F2;
    background-color: white;
}

.user-header-size{
    width: 1200px;
    height: 80%;
    margin: auto;
}

.user-div_link_produtos{
    width: 105px;
    height: 100%;
    float: left;
}

.user-div_link_encomendas{
    width: 105px;
    height: 100%;
    float: left;
}

.user-div_link_conta{
    width: 50px;
    height: 100%;
    float: right;
}

.user-div_link_cart{
    width: 50px;
    height: 100%;
    float: right;
}

.user-div_link_sair{
    width: 100px;
    height: 100%;
    float: right;
}

.div_link_cart-icon{
    font-size: 20px;
    margin-top: 29px;
    color: black;
}

.user-link{
    display: inline;
    margin-top: 30px;
    float: left;
    color: black;
    font-size: 14px;
    font-family: 'Source Code Pro';
}

.user-link:hover{
    font-family: 'Source Code Pro';
    font-weight: 600;
}

.selected{
    font-weight: 600;
}

.user-div_link_sair{
    width: 70px;
    height: 100%;
    float: right;
}

.user-logout{
    cursor: pointer;
    float: right;
}

.user-div_link_conta-iconAccount{
    font-size: 20px;
    color: black;
    margin-top: 29px;
    cursor: pointer;
}

.user-div_link_numcart{
    width: 32px;
    height: 100%;
    float: right;
}

.user-div_link_numcart-tiems{
    height: 20px;
    width: 20px;
    border-radius: 20px;
    margin-top: 29px;
    background-color: rgb(252, 79, 79);
}

.user-div_link_numcart-tiems p{
    color: black;
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    padding-top: 3px;
}

.user-div_link_produtos_logo{
    width: 100px;
    height: 100%;
    float: left;
}

.user-div_link_produtos_logo img{
    width: 65px;
    margin-left: 0;
    margin-top: 27px;
}

@media print{
    .user-header{
        display: none;
    }
}