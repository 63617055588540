.quantitySize-image{
    width: 12%;
    height: 210px;
    background-color: #F7F7F7;
    float: left;
    margin-right: 1%;
    margin-bottom: 20px;
    margin-top: 20px;
}

.quantitySize-image-sku{
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 14px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.quantitySize-image-divimg{
    width: 80%;
    height: 140px;
    margin: auto;
}

.quantitySize-image-divimg img{
    width: 100%;
    height: 100%;
}

.quantitySize-div-totalbuttom{
    width: 100%;
    height: 70px;
    margin-top: 20px;
    float: left;
}

.quantitySize-div-totalbuttom-buttom{
    width: 250px;
    height: 35px;
    float: right;
}

.quantitySize-div-totalbuttom-total{
    width: 180px;
    height: 50px;
    float: right;
}

.quantitySize-div-totalbuttom-total p{
 margin-top: 7px;
 font-size: 17px;
 font-family: 'Source Code Pro';
 font-weight: 300;
}

.quantitySize-generalInfo-cat{
    font-size: 11px;
    font-family: 'Source Code Pro';
    font-weight: 600;
    margin-bottom: 2px;
}

.quantitySize-generalInfo-title{
    font-size: 22px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-top: 4px;
}

.quantitySize-generalInfo-info{
    font-size: 12px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}