.purchaseConfirmation{
    width: 500px;
    height: 400px;
    margin: auto;
    margin-top: 50px;
}

.purchaseConfirmation-thanks{
    font-size: 30px;
    font-weight: 600;
    text-align: center;
}

.purchaseConfirmation-order{
    text-align: center;
}