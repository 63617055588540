.deleteDialog_div{
    background-color: rgb(220, 220, 220);
}

.deleteDialog_delete_button{
    width: 150px;
    height: 30px;
    border-radius: 4px;
    border: 0;
    background-color: rgb(216, 0, 0);
    color: white;
    font-size: 13px;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 5px;
    float: right;
}

.deleteDialog_delete_button:hover{
    cursor: pointer;
    background-color: red;
}

.deleteDialog_save_button{
    width: 150px;
    height: 30px;
    border-radius: 4px;
    border: 0;
    background-color: green;
    color: white;
    font-size: 13px;
    font-weight: 600;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 5px;
    float: right;
}

.deleteDialog_save_button:hover{
    cursor: pointer;
    background-color: rgb(0, 159, 0);
}

.deleteDialog_cancel_button{
    width: 150px;
    height: 30px;
    border-radius: 4px;
    border: 1px solid black;
    color: black;
    background-color: rgb(220, 220, 220);
    font-size: 13px;
    font-weight: 600;
    margin-left: 30px;
    margin-top: 5px;
}

.deleteDialog_cancel_button:hover{
    cursor: pointer;
    background-color: rgb(237, 236, 236);
}

.deleteDialog_img{
    font-size: 55px;
    color: red;
}

.deleteDialog_img2{
    font-size: 55px;
    color: green;
}

.div_deleteDialog_img{
    width: 55px;
    margin: auto;
    margin-top: 20px;
}

.deleteDialog_div h3{
    margin-top: 5px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 700;  
}