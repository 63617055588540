.main{
    width: 100%;
    height: 120px;
    background-color: white;
    border-radius: 4px;
    margin-right: 20px;
    display: inline-block;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    margin-bottom: 22px;
}

.line{
    width: 100%;
    height: 1px;
    background-color: rgb(184, 184, 184);
}

.title{
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-left: 20px;
}

.value{
    font-size: 27px;
    margin-top: 15px;
    font-family: 'Source Code Pro';
    font-weight: 300;
    margin-left: 20px;
}