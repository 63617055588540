.userOrdersOrder-div{
    width: 1198px;
    height: 75px;
    border: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
    margin-bottom: 10px;
}

.userOrdersOrder-div:hover{
    cursor: pointer;
    background-color: rgb(255, 251, 251);
}

.userOrdersOrder-div-id{
    height: 100%;
    float: left;
    color: black;
    width: 55%;
}

.userOrdersOrder-div-date{
    width: 18%;
    height: 100%;
    float: left;
    color: black;
}

.userOrdersOrder-div-fulfilled{
    width: 17%;
    height: 100%;
    float: left;
    color: black;
}

.userOrdersOrder-div-total{
    width: 10%;
    height: 100%;
    float: left;
    color: black;
}

.adminOrdersOrder-div-div3-title{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: black;
    margin-left: 20px;
    width: 110px;
    text-align: center;
}

.adminOrdersOrder-div-div3-title-f{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: black;
    width: 90px;
    text-align: center;
}

.adminOrdersOrder-div-div3-data{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 4px;
    margin-top: 28px;
    color: rgb(104, 101, 101);
    width: 110px;
    text-align: center;
}
