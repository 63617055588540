.admin_account{
    min-height: 100vh;
    margin-left: 230px;
    background-color: #F4F3F8;
    overflow: hidden;
}

.admin_account_div{
    width: 379px;
    height: 350px;
    margin: auto;
    background-color: white;
    padding-left: 30px;
    padding-top: 14px;
    margin-top: 200px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    border-radius: 4px;
}

.userAccount-div-p{
    font-size: 12px;
}

.userAccount-div-input{
    width: 98.5%;
    height: 25px;
    border: 1px solid #e6dfda;
    font-size: 10px;
    outline: none;
}

.userAccount-div-button{
    width: 100%;
    height: 30px;
    border-radius: 0px;
    border: 1px solid #d5cbc4;
    cursor: pointer;
    background-color: #d5cbc4;
    margin-top: 20px;
}

.userAccount-div-button:hover{
    background-color: #ebdfd6;
}

.adminAccount-p{
    font-size: 13px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.admin_account_button{
    width: 92%;
    text-align: center;
}