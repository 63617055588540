.shopcartOrder-qty{
    width: 150px;
    float: right;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
    margin-top: 18px;
}

.shopcart-button-edit{
    float: right;
    margin-top: 12px;
    margin-right: 40px;
}

.shopcartOrder-dontforget{
    width: 300px;
    float: right;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
    margin-top: 18px;
    color: red;
}

.shopcartOrder-trashIcon{
    color: red;
    float: right;
    font-size: 19px;
    margin-top: 15px;
    margin-right: 34px;
}

.shopcartOrder-trashIcon:hover{
    cursor: pointer;
}

.qty_newdiv_size{
    width: 100%;
    height: 185px;
    overflow: hidden;
}

.quantitySizeAllforShopingCart{
    height: 250px;
    width: 87%;
    float: left;
    background-color: #F7F7F7;
    margin-bottom: 20px;
    margin-top: 20px;
}

.quantitySize{
    height: 200px;
    width: 100%;
    float: left;
    background-color: #F7F7F7;
    overflow-x: scroll;
    white-space: nowrap;
}

.quantitySize_shoppingmoreinfo{
    height: 50px;
    width: 100%;
    float: left;
    background-color: #F7F7F7;
}

::-webkit-scrollbar  {
    -webkit-appearance: none;
    height: 9px;
  }
  
  ::-webkit-scrollbar-thumb:horizontal  {
    border-radius: 0px;
    background-color: #b1b1b1;
    height: 2px;
    width: 10px;
  }