.adminOrderDetail{
    min-height: 100vh;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-top: 0px;
}

.id{
    display: inline-block;
    font-family: 'Source Code Pro';
    font-size: 15px;
    margin-left: 20px;
    margin-bottom: 10px;
}

.id span{
    font-weight: 600;
}

.email{
    font-family: 'Source Code Pro';
    font-size: 15px;
    margin-left: 20px;
    margin-top: 5px;
    margin-bottom: 10px;
}

.header{
    width: 100%;
    height: 90px;
    background-color: white;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    margin-bottom: 20px;
}

.header1{
    width: 50%;
    height: 100;
    float: left;
}

.header2{
    width: 50%;
    height: 100%;
    float: left;
}

.header2 button{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #052F56;
    color: white;
    font-size: 13px;
    font-weight: 600;
    float: right;
    margin-right: 20px;
    margin-top: 37px;
}

.header2 button{
    cursor: pointer;
}