/* principal div */
.admin_addproduct{
    height: 1230px;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-top: 0px;
}

.admin_addproduct2{
    min-height: 100vh;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-top: 0px;
    max-width: 1170px;
    margin: auto;
}

.adminAddProduct-textarea{
    width: 100%;
    height: 100px;
    outline-color: #AFAFAF;
    border: 1px solid #AFAFAF;
    border-radius: 0;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
}

.adminAddProduct-p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 14px;
}

.adminAddProduct-h1{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 25px;
    margin: 0;
}

.adminAddProduct-legend{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 17px;
    margin-left: 30px;
}

.admin-qtySizeData-div{
    width: 55px;
    height: 235px;
    margin-left: 6px;
    overflow: hidden;
    display: inline-block;
}

.admin-qtySizeData-div-size{
    width: 95%;
    height: 30px;
    border: 1px solid #D4D2D6;
    overflow: hidden;
    margin-top: 4px;
    border-radius: 3px;
}

.admin-qtySizeData-div-size p{
    margin: 0;
    margin-top: 7px;
    font-size: 13px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.admin-qtySizeData-div-size input{
    width: 90%;
    border: 0;
    outline: none;
    font-size: 13px;
    margin-top: 6px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.createProduct_load_div h2{
    text-align: center;
}

.editProduct_div3_main input{
    display: none;
}

.editProduct_div3_main label{
    margin-left: 155px;
}

.addproduct_icon{
    margin-top: 10px;
    font-size: 20px;
    color:#747474;
}

.addproduct_icon:hover{
    cursor: pointer;
}

/* Icon secundary photos */
.addproduct_icon2{
    margin-left: 30px;
    margin-top: 37px;
    font-size: 20px;
    color:#747474;
}

.addproduct_icon2:hover{
    cursor: pointer;
}

/*  */

.addProduct_quantitys{
    width: 1140px;
    height: 283px;
    background-color: white;
    float: left;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.addProduct_quantitys_title{
    margin-bottom: 0px;
    font-weight: 600;
    margin-left: 20px;
    font-size: 15px;
}


.admin_addproduct_div_button{
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: inline-block;
  }

.admin_addproduct_div_button button{
  width: 170px;
  height: 35px;
  border-radius: 4px;
  border: 0;
  background-color: #FF3939;
  color: white;
  font-size: 13px;
  font-weight: 600;
  margin-left: 21px;
}

.addProduct_div3_submain_img1{
    height: 87px;
    width: 87px;
    border-radius: 4px;
    float: left;
}

.addProduct_div3_submain_img2{
    height: 87px;
    width: 87px;
    border-radius: 4px;
    float: left;
}

.addProduct_div3_submain_img3{
    height: 87px;
    width: 87px;
    border-radius: 4px;
    float: left;
}

.addProduct_div3_submain{
    height: 80%;
    width: 170px;
    float: left;
    margin-top: 20px;
}

.addProduct_div3_submain input{
    display: none;
}

/* divs secundary photos */

.addProduct_div3_submain div{
    width: 100%;
    height: 100px;
}

.addProduct_tag{
    background-color:#E2E2E2;
}

.addProduct_tag:hover{
    cursor: pointer;
    background-color: #FF3939;
}