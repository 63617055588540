

.userBanner h1{
    text-align: center;
    margin-top: 70px;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 700;
}

.userBanner img{
    text-align: center;
    margin-top: 30px;
    margin-left: 545px;
}

.userBanner button{
    margin-left: 630px;
    margin-bottom: 20px;
    margin-top: 50px;
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #052F56;
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.userBanner button:hover{
    cursor: pointer;
    background-color: #095398;
}