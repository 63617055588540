.div_produtos{
    width: 1200px;
    margin: auto;
    margin-top: 30px;
    overflow: auto;
}

.div_produtos-categories{
    width: 17%;
    height: 100%;
    float: left;

}

.div_produtos-products{
    width: 83%;
    height: 102%;
    float: left;
}

.div_produtos-categories-p{
    display: inline;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.div_produtos-categories-category{
    margin-top: 10px;
}

.div_produtos-categories-p-categoriesTitle{
    margin-top: 0;
    font-size: 20px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 25px;
}

.div_produtos-categories-p-categoriesTitle2{
    margin-top: 0;
    font-size: 20px;
    font-family: 'Source Code Pro';
    font-weight: 500;
    margin-bottom: 25px;
    margin-top: 40px;
}

.category-checkbox{
    height: 15px;
    width: 15px;
}

.div_produtos_checkbox{
    width: 10px;
    height: 10px;
    border: 1px solid #c2c2c2;
    float: left;
    margin-top: 4px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
}

@media only screen and (max-width: 480px) {

}