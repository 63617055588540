.div_produto_imagens{
    width: 63%;
    height: 550px;
    float: left;
}

.div_produto_imagens-img_sec{
    width: 13%;
    height: 100%;
    float: left;
}

.div_produto_imagens-img_sec-div_img{
    width: 95px;
    height: 125px;
    margin-bottom: 17px;
    float: left;
    cursor: pointer;
}

.div_produto_imagens-img_sec-div_img img{
    width: 125px;
    height: 125px;
}

.div_produto_imagens-img_sec-div_img img:hover{
    opacity: 0.5;
}

.div_produto_imagens-img_sec-div_img2{
    width: 95px;
    height: 89px;
    margin-bottom: 17px;
    float: left;
    cursor: pointer;
}

.div_produto_imagens-img_sec-div_img2 img{
    width: 95px;
    height: 89px;
}

.div_produto_imagens-img_pri{
    width: 85%;
    height: 100%;
    float: left;
}

.div_produto_imagens-img_pri-div_img{
    height: 550px;
    width: 550px;
    margin: auto;
}

.div_produto_imagens-img_pri-div_img img{
    height: 550px;
    width: 550px;
}

.div_produto_imagens-img_pri-div_img2{
    height: 84%;
    width: 87%;
    margin: auto;
}
.div_produto_imagens-img_pri-div_img2 img{
    height: 84%;
    width: 87%;
}