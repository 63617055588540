.div-login{
    width: 100vw;
    height: 65vh;
    overflow: hidden;
    margin: 0;
}

.login-header{
    width: 100%;
    height: 10%;
}

.login-data{
    width: 100%;
    height: 50%;
    overflow: hidden;
}

.login-data_div{
    height: 200px;
    width: 250px;
    margin: auto;
    margin-top: 0px;
}

.p_login-data{
    font-size: 14px;
    margin-bottom: 7px;
    font-family: 'Source Code Pro';
}

.input_login-data{
    width: 98.5%;
    height: 27px;
    border: 1px solid #e6dfda;
    border-radius: 5px;
    font-size: 13px;
    outline-color: #c7c4c4;
    font-family: 'Source Code Pro';
    font-weight: 500;
}

.button_login{
    width: 120px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid #e6dfda;
    font-size: 14px;
    float: right;
    cursor: pointer;
    margin-top: 20px;
    background-color: #052F56;
    color: white;
    font-family: 'Source Code Pro';
    font-weight: 600;
}

.button_login:hover{
    background-color: #07457e;
}

.p_login-forgotten{
    font-size: 10px;
    margin-top: 35px;
    font-family: 'Source Code Pro';
    color: #4e4e4e;
    text-decoration: none;
    display: inline-block;
}

.p_login-forgotten:hover{
    font-weight: 900;
}

.div-login-img{
    width: 100%;
    margin-top: 50px;
    margin-bottom: 0;
}

.div-login-img img{
    display: block;
    margin: auto;
    width: 100px;
    margin-bottom: 0;
}

@media only screen and (max-width: 480px) {

    .div-login{
        width: 100vw;
        height: 100vh;
        overflow: hidden;
        margin: 0;
    }

}