.admin_user{
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #ededed;
}

.admin_user:hover{
    background-color: #f7f7f7;
}

.adminUser-div-div1{
    width: 20%;
    height: 100%;
    float: left;
}
.adminUser-div-div2{
    width: 25%;
    height: 100%;
    float: left;
}
.adminUser-div-div3{
    width: 20%;
    height: 100%;
    float: left;
}
.adminUser-div-div4{
    width: 20%;
    height: 100%;
    float: left;
}
.adminUser-div-div5{
    width: 15%;
    height: 100%;
    float: left;
}

.adminUser-div-div3-data{
    color: black;
    text-align: center;
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
}

.admin_user_editIcon{
    color: black;
    text-align: center;
    font-size: 14px;
}