.admin_editUser_titleDiv{
    width: 100%;
    height: 120px;
    background-color: white;
}

.admin_editUser_responsiveTitle{
    width: 1150px;
    margin: auto;
}

.admin_editUser_responsiveTitle h1{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 40px;
    margin-top: 50px;
}

.editUser{
    margin-left: 230px;
    background-color: #F4F3F8;
    min-height: 150vh;
}

.editUser h1{
    display: inline-block;
}

.editUser_email{
    width: 565px;
    height: 90px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.editUser_email input{
    width: 80%;
    height: 30px; 
    margin-left: 7%;
    border: 1px solid #AFAFAF;
    border-radius: 4px;
    padding-left: 10px;
    outline-color: #AFAFAF;
    font-size: 13px;
}

.editUser_email p{
    margin-left: 7%;
    margin-bottom: 9px;
    margin-top: 10px;
    font-size: 14px;
}

.editUser_password{
    width: 565px;
    height: 90px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.editUser_password input{
    width: 80%;
    height: 30px; 
    margin-left: 7%;
    border: 1px solid #AFAFAF;
    border-radius: 4px;
    padding-left: 10px;
    outline-color: #AFAFAF;
    font-size: 13px;
}

.editUser_password p{
    margin-left: 7%;
    margin-bottom: 9px;
    margin-top: 10px;
    font-size: 14px;
}

.company_details_div{
    width: 1150px;
    height: 290px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-bottom: 20px;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.company_details_div_1{
    width: 100%;
    height: 35px;
    border-bottom: 1px solid #e3e3e3;
}

.company_details_div_1 p{
    font-size: 15px;
    font-weight: 600;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 0px;
}

.company_details_div_companyName{
    width: 356px;
    height: 90px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.company_details_div_companyName input{
    width: 80%;
    height: 30px; 
    margin-left: 7%;
    border: 1px solid #AFAFAF;
    border-radius: 4px;
    padding-left: 10px;
    outline-color: #AFAFAF;
    font-size: 13px;
}

.company_details_div_companyName p{
    margin-left: 7%;
    margin-bottom: 9px;
    margin-top: 10px;
    font-size: 14px;
}

.company_details_div_cityCountry{
    width: 168px;
    height: 90px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.company_details_div_cityCountry input{
    width: 80%;
    height: 30px; 
    margin-left: 7%;
    border: 1px solid #AFAFAF;
    border-radius: 4px;
    padding-left: 10px;
    outline-color: #AFAFAF;
    font-size: 13px;
}

.company_details_div_cityCountry p{
    margin-left: 7%;
    margin-bottom: 9px;
    margin-top: 10px;
    font-size: 14px;
}

.shippingAdress_div{
    width: 1150px;
    height: 180px;
    float: left;
    background-color: white;
    overflow: auto;
    margin-bottom: 20px;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
}

.confirmationDialog{
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    border-radius: 5px;
    color: green;
    text-align: center;
    font-size: 20px;
    font-family: Source Code Pro; 
    font-weight: 600;
}