.admin_orders{
    min-height: 100vh;
    margin-left: 230px;
    background-color: #F4F3F8;
    padding-bottom: 20px;
}

.admin_orders_div{
    width: 1170px;
    background-color: white;
    margin-left: 20px;
    border-radius: 4px;
    box-shadow: 0px 0px 10px 2px rgb(222, 222, 222);
    margin: auto;
}

.adminOrdersOrder-onequarter1t{
    width: 18%;
    float: left;
}

.adminOrdersOrder-onequarter2t{
    width: 25%;
    float: left;
}

.adminOrdersOrder-onequarter3t{
    width: 14%;
    float: left;
}

.adminOrdersOrder-onequarter4t{
    width: 8%;
    float: left;
}

.adminOrdersOrder-onequarter5t{
    width: 10%;
    float: left;
}

.adminOrdersOrder-onequarter6t{
    width: 12.5%;
    float: left;
}

.adminOrdersOrder-onequarter7t{
    width: 12.5%;
    float: left;
}

.adminOrdersOrder-onequarter1-p{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color:#B5B4B7;
}

.adminOrdersOrder-onequarter1-p2{
    font-family: 'Source Code Pro';
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    color:#B5B4B7;
    margin-top: 27px;
}

.admin_orders_header_div{
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ededed;
}

/* Shopify css */

.admin_orderShopify_button{
    width: 90px;
    height: 25px;
    float: right;
    margin-top: 50px;
    margin-right: 20px;
    border-radius: 4px;
    border: 1px solid #052F56;
}

.admin_orderShopify_button2{
    width: 90px;
    height: 25px;
    float: right;
    margin-top: 50px;
    border-radius: 4px;
    border: 1px solid #052F56;
}

.admin_orderShopify_button:hover{
    cursor: pointer;
    background-color: #55789a;
}

.admin_orderShopify_button2:hover{
    cursor: pointer;
    background-color: #55789a;
}

.admin_orderShopify_button p{
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
}

.admin_orderShopify_button2 p{
    font-size: 12px;
    text-align: center;
    margin-top: 5px;
}