.button{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #052F56;
    color: white;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Source Code Pro';
}

.button:hover{
    cursor: pointer;
    background-color: #07457e;
}

.button_save{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: rgb(0, 171, 0);
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.button_save:hover{
    cursor: pointer;
    background-color: rgb(0, 194, 0);
}

.button_delete{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: rgb(218, 31, 31);
    color: white;
    font-size: 13px;
    font-weight: 600;
}

.button_delete:hover{
    cursor: pointer;
    background-color: red;
}

.button_back{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    border: 1px solid #052F56;
    background-color: white;
    color: black;
    font-size: 13px;
    font-weight: 600;
}

.button_back:hover{
    cursor: pointer;
    background-color: rgb(245, 245, 245);
}

/* Disabled button */

.button_disabled{
    width: 170px;
    height: 35px;
    border-radius: 4px;
    border: 0;
    background-color: #97a6b3;
    color: white;
    font-size: 13px;
    font-weight: 600;
    font-family: 'Source Code Pro';
}
