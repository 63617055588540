.shopcartOrderCom-qty{
    font-size: 11px;
    text-align: center;
    margin-top: 9px;
}

.shopcart-qtySizeData-div77{
    width: 55px;
    height: 125px;
    display: inline-block;
    margin-left: 6px;
    margin-top: 25px;
}